@charset "utf-8";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: auto;
  font-size: $baseFontSize + px;
  font-size: clamp(14.6666px, calc(.20833vw + 12px), 10000px);
  @include lang-ja;
  @media screen and (max-width: 1280px) {
    font-size: 14.6666px;
  }
  @include mq(sp) {
    font-size: 4vw;
  }
}

button {
  -webkit-appearance: button;
  outline: none;
  border: none;
  padding: 0;
}

body {
  width: 100%;
  line-height: 1.8;
  color: $baseColor;
  word-wrap: break-word;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  // position: relative;
  @include mq(sp) {
    min-width: auto;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

main {
  display: block;
}

// IE11 SVGサイズ崩れハック
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$='.svg'] {
    width: 100%;
    height: auto;
  }
}
.sp {
  @include mq(pc) {
    display: none !important;
  }
}

.pc {
  @include mq(sp) {
    display: none !important;
  }
}
