.-is-templateA {
  .c-archive-list {
    @include mq(sp) {
      margin: 0 -2vw;
    }
  }
  .c-archive__item {
    flex: 0 1 680/1440*100%;
    margin: 100px 0 0;
    @include mq(ie) {
      max-width: 680/1440*100%;
    }
    &:first-child {
      @include mq(sp) {
        margin-top: 70px;
      }
    }
    &:nth-child(odd) {
      @include mq(pc) {
        margin-right: 80/1440*100%;
      }
    }
    @include mq(sp) {
      margin-top: 40px;
    }
  }
  .c-archive__link {
    position: relative;
    width: 100%;
    display: block;
    &::after {
      content: "";
      display: block;
      padding-top: 52.05883%;
      @include mq(sp) {
        padding-top: calc(192 / 376 * 100%);
      }
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      background: url(../images/list_shadow_01.png) no-repeat 0 0 / 100% 100%;
      width: 100%;
      height: 16px;
      @include mq(sp) {
        bottom: -4px;
        height: 10px;
      }
    }
    &:hover {
      .c-archive__link-inner {
        // &::before {
        //   opacity: 1;
        //   transform: scale(1);
        // }
      }
      .c-archive__thumnail {
        // &::before {
        //   opacity: 1;
        // }
      }
    }
  }
  .c-archive__link-inner {
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0 12/680*100%;
    @include mq(sp) {
      padding: 0 2vw;
    }
  }
  .c-archive__thumnail {
    position: relative;
    border-radius: 12px;
    border: 2px solid $baseColor;
    background: #fff;
    @include mq(sp) {
      border-radius: 8px;
    }
    img {
      border-radius: 5px;
    }
    &.-is-thumail-pc {
      flex: 0 1 74.072%;
      padding: 2.0588%;
      @include mq(sp) {
        padding: calc(6 / 360 * 100%);
        flex: 0 1 calc(255 / 360 * 100%);
      }
    }
    &.-is-thumail-sp {
      flex: 0 1 25.928%;
      // margin-left: 24/680*100%;
      margin-left: 3.65853%;
      padding: 2.0588% .8823%;
      @include mq(sp) {
        padding: calc(6 / 360 * 100%) calc(4 / 360 * 100%);
        flex: 0 1 calc(93 / 360 * 100%);
        margin-left: calc(12 / 360 * 100%);
      }
    }
  }
}