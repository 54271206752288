.l-footer {
}

.c-footer {
  z-index: 2;
  position: relative;
  text-align: center;
  padding: 11px 0 17px;
  background: $baseColor;
  color: #fff;
}

.c-footer__copy {
  small {
    font-size: rem(12);
  }
}
