



.c-single {
  margin-top: 100px;
  @include mq(sp) {
    margin-top: 70px;
  }
}

.c-single-relation {
  // @extend .l-inner;
  margin-top: 100px;
  @include mq(sp) {
    margin-top: 70px;
  }
  @include mq(ie) {
    max-width: 1120px;
    margin: 0 auto;
    min-width: 880px;
  }
}

// @media all and (-ms-high-contrast: none) {
//   .c-single-relation {
//     @extend .l-inner;
//   }
// }

.c-single-relation-section {
  @include mq(sp) {
    padding-left: 0;
    padding-right: 0;
  }
}

.c-single-relation__heading {
  font-size: rem(21);
  font-weight: bold;
  @include mq(sp) {
    padding-left: 5%;
    padding-right: 5%;
    font-size: rem(18);
  }
}

.c-single-relation__module {
  margin-top: 32px;
}

.c-single-relation-card {

}

.c-single-relation-card__link {

}

.c-single-relation-card__photo {
  display: block;
  position: relative;
  // padding: 6px;
  padding-right: 4px;
  padding-bottom: 4px;
  &::after {
    bottom: 0;
    right: 0;
    // top: 6px;
    // right: -6px;
    content: "";
    position: absolute;
    background: url(../images/common_shadow_02.png) no-repeat 0 0 / 100% 100%;
    // width: 100%;
    // height: 100%;
    // z-index: -1;
    width: 99.12%;
    height: 98.42%;
    z-index: -1;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    padding: 6px;
  }
}

.c-single-relation-card__photo-inner {
  background: #fff;
  border: 2px solid $baseColor;
  position: relative;
  &::after {
    display: block;
    content: "";
    padding-bottom: 69.4%;
  }
}

.c-single-relation-card__description {
  margin-top: 20px;
  line-height: 1.6;
}

.c-single-relation-card__ttl {
  font-size: rem(18);
  font-weight: bold;
  transition: $transition;
  @include mq(sp) {
    font-size: rem(16);
  }
  &:hover {
    opacity: $opacity;
  }
}

.c-single-relation-card__relation {
  display: block;
  margin-top: 4px;
  font-size: rem(15);
  color: $grayText;
  @include mq(sp) {
    font-size: rem(14);
  }
}

.c-single-relation-wrapper {
  display: flex;
}

.-is-single {
  .c-single-relation-card {
    flex: 0 1 calc(100%/3);
    @media all and (-ms-high-contrast: none) {
      max-width: 344px;
    }
    @include mq(sp) {
      flex: 0 1 100%;
      padding: 0 8px;
      margin: 0 5%;
    }
    &:not(:last-child) {
      margin-right: 44px;
    }
  }
}


.c-single__header {
  line-height: 1.6;
}

.c-single__heading {
  font-weight: bold;
  font-size: rem(40);
  @include mq(sp) {
    font-size: rem(32);
  }
}

.c-single__relation {
  display: block;
  margin-top: 12px;
  color: $grayText;
  font-size: rem(15);
  font-weight: 500;
  @include mq(sp) {
    font-size: rem(14);
  }
}
