$breakpoints: (
  'pc': 'screen and (min-width: 768px)',//pc
  'sp': 'screen and (max-width: 767px)',//sp
  'small':'screen and (max-width: 400px)',//〜iphone7
  'ie':'all and (-ms-high-contrast: none)',
  'basepoint':'screen and (max-width: 1300px)' //1280px以下から各要素を可変させない。
) !default;

$z-index: (
  'header': 100,
  'nav':200,
  'contact':10
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

$baseFontSize: 16;

$transition:.3s;
$opacity:.75;
$baseColor:#111;
$primaryColor:#ffe100;
$gray:#f7f7f5;
$grayText:#999;

$colors:(
  baseColor:$baseColor,
  primaryColor:$primaryColor,
  gray:$gray,
  grayText:$grayText
);

@each $name,$color in $colors {
  .-color-button-#{$name}:not(:root) {
    background: $color;
    @if $name == baseColor {
      color: $primaryColor;
      svg {
        fill: $primaryColor;
      }
      &:hover {
        background: $primaryColor;
        color: $baseColor;
        svg {
          fill: $baseColor;
        }
      }
    }
    @if $name == primaryColor {
      color: $baseColor;
      svg {
        fill: $baseColor;
      }
      &:hover {
        background: $baseColor;
        color: $primaryColor;
        svg {
          fill: $primaryColor;
        }
      }
    }
  }
}