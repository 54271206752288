%c-single-article {
  border: 2px solid $baseColor;
  border-radius: 12px;
  background: #fff;
  @include mq(sp) {
    border-radius: 8px;
  }
}

%caption {
  margin: 16px 0 0;
  text-align: center;
  font-size: rem(16);
}

$image-padding:6/360*100%;

.c-single-contents {
  & > * {
    margin-top: 80px;
    @include mq(sp) {
      margin-top: 60px;
    }
  }
  h2,h3,h4 {
    margin-bottom: 24px;
    font-size: rem(21);
    font-weight: bold;
    @include mq(sp) {
      font-size: rem(18);
    }
    & + * {
      margin-top: 0;
    }
  }
  //グループ　上書き
  .wp-block-group {
    p {
      & + p {
        margin-top: 12px;
      }
    }
  }
  //画像一枚　上書き
  .wp-block-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    img {
      padding: 14/1120*100%;
      @extend %c-single-article;
    }
    figcaption {
      @extend %caption;
    }
  }
  //ブロックエディタ メディア　上書き
  .wp-block-media-text {
    display: flex;
    @include mq(sp) {
      display: block;
    }
    .wp-block-media-text__media {
      position: relative;
      flex: 0 1 560/1120*100%;
      width: 560/1120*100%;
      @extend %c-single-article;
      padding: 0;
      @include mq(sp) {
        width: 100%;
      }
      &::after {
        content: "";
        display: block;
        padding-bottom: 64.3%;
        @include mq(sp) {
          padding-bottom: 63.9%;
        }
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        padding: 14/560*100%;
        @include mq(sp) {
          padding: $image-padding;
        }
      }
    }
    .wp-block-media-text__content {
      align-self: flex-start;
      flex: 0 1 calc(100% - 560/1120*100%);
      width: calc(100% - 560/1120*100%);
      font-size: rem(16);
      @include mq(pc) {
        padding: 0 0 0 24/1120*100%;
      }
      @include mq(sp) {
        margin-top: 24px;
        width: 100%;
        padding: 0;
      }
      p {
        & + p {
          margin-top: 12px;
        }
      }
    }
    &.has-media-on-the-right {
      .wp-block-media-text__media {
        @include mq(pc) {
          order: 2;
        }
      }
      .wp-block-media-text__content {
        @include mq(pc) {
          padding: 0 24/1120*100% 0 0;
        }
      }
    }
  }
  //youtube 上書き
  .is-type-video {
    width: 880/1120*100%;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: 100%;
    }
    .wp-block-embed__wrapper {
      @extend %c-single-article;
      position: relative;
      width: 100%;
      padding-top: 58%;
      @include mq(sp) {
        padding-top: 58.3%;
      }
      iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 14/880*100%;
        -webkit-mask-image: url('../images/single_mask_movie_pc.svg');
        mask-image: url('../images/single_mask_movie_pc.svg');
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-size: 96%;
        mask-size: 96%;
        @include mq(sp) {
          padding: $image-padding;
        -webkit-mask-image: url('../images/single_mask_movie_sp.svg');
        mask-image: url('../images/single_mask_movie_sp.svg');
        }
      }
    }
    figcaption {
      @extend %caption;
    }
  }
  .c-button-basic-box {
    margin-top: 80px;
    @include mq(sp) {
      margin-top: 60px;
    }
    .c-button-basic {
      padding: 24px 0;
      font-size: rem(18);
      width: 400px;
      margin: 0 auto;
      @include mq(sp) {
        width: 100%;
      }
      &:hover {
        svg {
          transform: translateX(5px);
        }
      }
      svg {
        transition: $transition;
        width: 20px;
        height: 20px;
        margin-left: 12px;
      }
    }
  }

}

.c-acf_column {
  @include mq(pc) {
    display: flex;
  }
}

.c-acf_column__item {
  &.c-acf_column_2__item {
    flex: 0 1 530/1120*100%;
    width: 530/1120*100%;
    @include mq(sp) {
      width: 100%;
    }
    &:nth-child(odd) {
      @include mq(pc) {
        margin-right: 60/1120*100%;
      }
    }
  }
  &.c-acf_column_3__item {
    flex: 0 1 344/1120*100%;
    width: 344/1120*100%;
    @include mq(sp) {
      width: 100%;
    }
    &:not(:last-child) {
      @include mq(pc) {
        margin-right: 44/1120*100%;
      }
    }
  }
  @include mq(sp) {
    & + & {
      margin-top: 40px;
    }
  }
}

.c-acf_column__image {
  @extend %c-single-article;
  // height: 100%;
  img {
    border-radius: 4px;
    @include mq(sp) {
      border-radius: 2px;
    }
  }
}

.c-acf_column__caption {
  @extend %caption;
}

.c-acf_column_2__image {
  padding: 14/530*100%;
  @include mq(sp) {
    padding: $image-padding;
  }
}

.c-acf_column_3__image {
  padding: 14/344*100%;
  @include mq(sp) {
    padding: $image-padding;
  }
}