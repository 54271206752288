.-is-templateC {
  .c-archive-list--grid {
    margin-left: 0;
    margin-right: 0;
  }
  .c-archive__description {
    @include mq(sp) {
      margin-top: 20px;
    }
  }
  .c-single-relation-wrapper {
    width: 100%;
    flex-wrap: wrap;
    @include mq(sp) {
      display: block;
    }
  }
  .c-single-relation-card {
    flex: 0 1 680/1440*100%;
  }
  .c-single-relation-card {
    margin-right: 0;
    @include mq(pc) {
      &:nth-child(odd) {
        margin-right: 100% - ((680/1440*100%)*2);
      }
    }
    @include mq(pc) {
      &:nth-child(n+3) {
        margin-top: 100px;
      }
    }
    @include mq(sp) {
      &:nth-child(n+2) {
        margin-top: 40px;
      }
    }
  }
  .c-single-relation-card__photo-inner {
    &::after {
      padding-bottom: 55.2255%;
    }
  }
  .c-single-relation-card__photo {
    padding-right: .8824%;
    padding-bottom: .8824%;
    @include mq(sp) {
      padding-right: calc(4 / 364 * 100%);
      padding-bottom: calc(4 / 364 * 100%);
      margin: 0 -1vw 0 0;
    }
    img {
      padding: 2.0588%;
      @include mq(sp) {
        padding: calc(6 / 356 * 100%);
      }
    }
  }
  .c-archive__description {
    margin-top: 20px;
    @include mq(sp) {
      padding: 0;
    }
  }
}