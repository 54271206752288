.c-slider-controller {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $baseColor;
  width: 48px;
  height: 48px;
  // top: 37%;
  top: 50%;
  transform: translateY(-50%);
  transition: $transition;
  @include mq(sp) {
    width: 32px;
    height: 32px;
    top: 40.67%;
  }
  &::after {
    content: none;
  }
  svg {
    transition: $transition;
    fill: $primaryColor;
    width: 20px;
    height: 20px;
    @include mq(sp) {
      width: 16px;
      height: 16px;
    }
  }
  &.swiper-button-prev {
    left: auto;
    right: calc(100% + 1.78%);
    @include mq(sp) {
      right: auto;
      left: 3%;
    }
    svg {
      transform: rotate(180deg) translateX(0);
    }
  }
  &.swiper-button-next {
    right: auto;
    left: calc(100% + 1.78%);
    @include mq(sp) {
      right: 3%;
      left: auto;
    }
  }
  &:hover {
    &.swiper-button-prev {
      svg {
        @include mq(pc) {
          transform: rotate(180deg) translateX(5px);
        }
      }
    }
    &.swiper-button-next {
      svg {
        @include mq(pc) {
          transform: translateX(5px);
        }
      }
    }
  }
}

.c-single-relation-slider__wrapper {
  position: relative;
}


