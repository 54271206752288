.c-main {
  // ,url('../images/background_illust_02.jpg') no-repeat bottom 12px left
}

.c-main-info {
  position: relative;
  padding: 16px 0 8px;
  background: $gray;
  font-size: rem(15);
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 20px;
    background: url('../images/common_bg_01.png') repeat-x bottom left;
    transform: rotate(-180deg);
  }
}

.c-main-info__list {
  display: flex;
  justify-content: flex-end;
  font-size: rem(15);
  font-weight: 500;
}

.c-main-info__item {
  position: relative;
  padding: 0 0 0 10px;
  &:not(:last-child) {
    padding-right: 12px;
    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 17px;
      background: $baseColor;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.c-content-body {
  padding-top: 36px;
  position: relative;
  z-index: 2;
  @include mq(sp) {
    padding-top: 0;
  }
}

.c-breadCrumbs {
  display: flex;
  font-size: rem(15);
  @include mq(sp) {
    font-size: rem(14);
    flex-wrap: wrap;
  }
}


.c-breadCrumbs__item {
  a {
    margin-right: 4px;
    color: $grayText;
    @extend .hover-opacity;
    span {
      border-bottom: 1px solid $grayText;
    }
  }
  &:not(:last-child) {
    position: relative;
    margin-right: 6px;
    &::after {
      content: ">";
      color: $grayText;
    }
  }
}


.c-cross-search {
  margin-top: 52px;
  font-size: rem(16);
  @include mq(sp) {
    font-size: rem(14);
  }
}



.c-cross-search-buttons {
  display: flex;
  flex-wrap: wrap;
  & + .c-archive-list {
    margin-top: 100px;
  }
}

.c-cross-search-buttons__item {
  padding: 7px 24px 8px;
  border: 2px solid $baseColor;
  margin: 8px 8px 0 0;
  transition: $transition;
  @include mq(sp) {
    padding: 5px 11px;
    margin: 4px 4px 0 0;
  }
  &[aria-selected="true"] {
    background: $baseColor;
    color: $primaryColor;
    &:hover {
      cursor: auto;
    }
    .c-cross-search-buttons__label {
      position: relative;
      border-bottom: 2px solid $primaryColor;
      img {
        width: 46px;
        height: 2px;
      }
    }
  }
  &[aria-selected="false"] {
    @extend .-color-button-primaryColor;
    &:hover {
      // border-color: $primaryColor;
    }
    .c-cross-search-buttons__label {
      border-bottom: 2px solid transparent;
    }
  }
}

.object-fit {
  overflow: hidden;
}

.object-fit__image {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.c-archive-list {
  // margin: 0 80/1440*-100%;
  margin: 0;
  @include mq(pc) {
    display: flex;
    flex-wrap: wrap;
  }
  &:not(.c-archive-list--normal) {
    margin-top: 100px;
    @include mq(sp) {
      margin-top: 70px;
    }
  }
}

.c-archive__description {
  position: relative;
  margin-top: 26px;
  font-weight: bold;
  line-height: 1.6;
  padding: 0 12/680*100%;
  @include mq(sp) {
    margin-top: 24px;
    position: relative;
    padding: 0 2vw;
  }
}
.c-archive__heading {
  // flex: 0 1 540px;
  // max-width: 540px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.c-archive__heading-coporate {
  font-size: rem(21);
  display: block;
  transition: $transition;
  @include mq(pc) {
    flex: 0 1 83.59%;
    max-width: 83.59%;
  }
  @include mq(sp) {
    font-size: rem(18);
  }
  &:hover {
    opacity: $opacity;
  }
}
.c-archive__heading-relation {
  display: block;
  margin-top: 4px;
  font-size: rem(15);
  color: $grayText;
  font-weight: normal;
  flex: 0 1 100%;
  @include mq(pc) {
    order: 2;
    flex: 0 1 83.59%;
    max-width: 83.59%;
  }
  @include mq(sp) {
    display: block;
    font-size: rem(13);
    flex: 0 1 72.2%;
  }
}
.c-archive__buttons {
  right: 12/680*100%;
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
  @include mq(sp) {
    margin-top: 4px;
  }
}
.c-archive__buttons-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $baseColor;
  width: 48px;
  height: 48px;
  transition: $transition;
  @include mq(sp) {
    width: 40 / 400 * 100vw;
    height: 40 / 400 * 100vw;
  }
  svg {
    fill: $primaryColor;
    width: 20px;
    height: 20px;
    transition: $transition;
    @include mq(sp) {
      width: 16 / 400 * 100vw;
      height: 16 / 400 * 100vw;
    }
  }
  & + .c-archive__buttons-item {
    margin-left: 4px;
  }
  &:hover {
    transition: $transition;
    background: $primaryColor;
    svg {
      fill: $baseColor;
    }
  }
}

.c-archive-list--grid {
  margin: 0 -1.4%;
  @include mq(sp) {
    margin: 0 -2.2%;
  }
  .c-archive__item {
    overflow: hidden;
  }

  .c-archive__link {
    margin: 5%;
    // margin: clamp(20px,1.25vw, 24px);
    display: block;
  }
  .c-archive__link-target {
    padding: 6px;
    border: 2px solid #e5e5e5;
    background: #fff;
    @include mq(sp) {
      padding: 2px;
    }
  }
}




