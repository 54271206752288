.l-wrapper {
  @include mq(pc) {
    padding-left: 240px;
    width: 100%;
    // min-width: calc(1120px + 12.5%);
    min-width: 1280px;
    overflow: hidden;
  }
  @include mq(sp) {
    overflow-x: hidden;
  }
  &.-is-open {
    position: fixed;
    overflow: hidden;
  }
}

.l-section-wrapper {
  padding-left: 6.25%;
  padding-right: 6.25%;
  padding-left: clamp(80px, 6.25vw, 10000px);
  padding-right: clamp(80px, 6.25vw, 10000px);
  @include mq(sp) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.l-inner {
  max-width: 1440px;
  margin: 0 auto;
  @include mq(pc) {
    min-width: 880px;
  }
}

.-is-single {
  .c-single {
    .l-inner {
      max-width: 1120px;
    }
    & + .l-section-wrapper {
      .l-inner {
        max-width: 1120px;
      }
    }
  }
}

//clamp関数が効かないブラウザ用
// .l-inner {
//   @include mq(ie) {
//     max-width: 1440px;
//     margin: 0 auto;
//     min-width: 880px;
//   }
// }
// .-is-single {
//   .l-inner {
//     @include mq(pc) {
//       max-width: 1120px;
//       margin: 0 auto;
//     }
//     @include mq(ie) {
//       min-width: 880px;
//     }

//   }
// }


