.c-pager {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
  @include mq(sp) {
    margin-top: 70px;
  }
}

.c-pager--illust {
  * {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: $baseColor;
    margin: 0 6px;
    transition: $transition;
  }
  & :not(span) {
    background: url(../images/common_pager_02.png) no-repeat 0 0 / cover;
    font-size: rem(18);
    width: 36px;
    height: 72px;
    &:hover {
      opacity: 1;
      background: url(../images/common_pager_02_hover.png) no-repeat 0 0 / cover;
      color: $primaryColor;
      span {
        color: $primaryColor;
      }
    }
  }
  a {
    transition: $transition;
  }
  // a[rel="prev"],a[rel="next"] {
  //   padding: 13px 10px;
  //   width: 56px;
  //   height: 56px;
  //   background: url(../images/common_pager_01.png) no-repeat 0 0 / cover;
  //   font-size: rem(16);
  // }
  // a[rel="prev"] {
  // }
  // a[rel="next"] {

  // }
  .extend {
    background: none;
  }
  a.page {

  }
  .current {
    position: relative;
    background: url(../images/common_pager_02_hover.png) no-repeat 0 0 / cover;
    font-size: 1.125rem;
    width: 36px;
    height: 72px;
    &:hover {
      opacity: 1;
      cursor: auto;
    }
    span {
      display: inline-block;
      position: relative;
      color: $primaryColor;
      margin: 0;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $primaryColor;
      }
    }
  }
}

.c-main.-is-single {
  .c-pager {
    position: relative;
    margin-top: 100px;
    padding-top: 64px;
    @include mq(sp) {
      padding-top: 54px;
      margin: 70px 0 0;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: url(../images/common_line_02.png) no-repeat 0 0;
    }
  }
}


.c-pager--single {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: bold;
  * {
    transition: $transition;
    &:hover {
      // opacity: $opacity;
    }
  }
}

.c-pager-prev {
  left: 0;
}

.c-pager-next {
  right: 0;
}

.c-pager--single-pageFeed {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 13px 10px;
  width: 56px;
  height: 56px;
  background: url(../images/common_pager_01.png) no-repeat 0 0 / cover;
  font-size: rem(16);
  &:hover {
    background: url(../images/common_pager_01_hover.png) no-repeat 0 0 / cover;
    color: $primaryColor;
  }
}

.c-pager--single-pageBack {
  width: 136px;
  padding: 15px 0;
  background: url(../images/common_pager_03.png) no-repeat 0 center/ 100% auto;
  &:hover {
    background: url(../images/common_pager_03_hover.png) no-repeat 0 center / 100% auto;
    color: $primaryColor;
    opacity: 1;
  }
}