@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hover-opacity {
  transition: $transition;
  &:hover {
    opacity: $opacity;
  }
}

.hover-target {
  position: relative;
  &::before {
    transition: $transition;
    opacity: 0;
    position: absolute;
    content: "";
    background: rgba(255, 225, 0, .6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.hover-more {
  position: relative;
  &::before {
    content: "";
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    line-height: 60px;
    background: url('../images/icon_detail.svg') no-repeat center $baseColor;
    color: $primaryColor;
    border-radius: 100%;
    transition: .5s;
    transform: translate(-50%,-50%) scale(.8);
    opacity: 0;
    z-index: 3;
    background-size: 24px;
  }
  &:hover {
    &::before {
      opacity: 1;
      transform: translate(-50%,-50%) scale(1);
    }
    .hover-target {
      &::before {
        opacity: 1;
      }
    }
  }
}

.c-archive__link {
  &.hover-target {
    overflow: hidden;
    &:hover {
      &::before {
        opacity: 1;
      }
      .hover-more {
        overflow: hidden;
        &::before {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}



