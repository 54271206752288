.c-contact {
  position: relative;
  padding: 40px 0 80px;
  background: $gray;
  @include mq(sp) {
    @include z-index(contact);
    padding-bottom: 60px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 20px;
    background: url('../images/common_bg_01.png') repeat-x 0 0;
  }
}

.c-contact__img {
  width: 164px;
  margin: 0 auto;
  @include mq(sp) {
    width: 164/360*100%;
  }
}

.c-contact__lead {
  margin-top: 16px;
  text-align: center;
  font-size: rem(21);
  @include mq(sp) {
    font-size: rem(20);
  }
}

.c-contact__list {
  @include mq(pc) {
    margin: 40px -20px 0;
    display: flex;
  }
  @include mq(sp) {
    margin-top: 24px;
  }
}

.c-contact__item {
  position: relative;
  flex: 0 1 50%;
  // padding: 40px 0;
  background: #fff;
  @include mq(pc) {
    margin: 0 20px;
    padding: 40px 0;
  }
  & + & {
    @include mq(sp) {
      margin-top: 16px;
    }
  }
  &.-is-tel {
    @include mq(sp) {
      padding: 40px 10px;
    }
    .c-contact__heading {
      position: relative;
      margin-bottom: 12px;
    }
  }
  &.-is-web {
    @include mq(sp) {
      padding: 40px 20px;
    }
  }
}

.c-contact__item-inner {
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  // width: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;
}

.c-contact__heading {
  position: relative;
  font-size: rem(21);
  padding: 0 12px;
  z-index: 1;
  font-weight: bold;
  &::before {
    position: absolute;
    content: '';
    background: url('../images/contact_line_01.jpg') no-repeat bottom center;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 16px;
    background-size: contain;
    z-index: -1;
    // position: absolute;
    // background: url('../images/contact_line_01.jpg') no-repeat bottom center;
  }
}

.c-contact-tel-box {
  display: flex;
  align-items: center;
  svg {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    top: 2px;
    position: relative;
    @include mq(sp) {
      width: 32px;
      height: 32px;
    }
  }
}



.c-contact-web-box {
  @include mq(sp) {
    width: 100%;
  }
  .c-button-basic {
    margin-top: 24px;
    padding: 28px 72px;
    background: $baseColor;
    color: $primaryColor;
    font-size: rem(18);
    // border: 2px solid $baseColor;
    transition: $transition;
    @include mq(sp) {
      padding: 26px 0;
      width: 100%;
    }
    svg {
      fill: $primaryColor;
      width: 24px;
      height: 24px;
      margin-top: 2px;
      margin-right: 12px;
    }
    &:hover {
      background: $primaryColor;
      color: $baseColor;
      svg {
        fill: $baseColor;
      }
    }
  }
}

.c-contact-number {
  font-size: rem(44);
  @include mq(sp) {
    font-size: rem(40);
  }
}

.c-contact-date {
  font-size: rem(16);
}

.c-contact__note {
  margin-top: 32px;
  text-align: center;
  font-size: rem(15);
  @include mq(sp) {
    font-size: rem(14.1);
  }
}

.c-contact-number__link {
  &:hover {
    cursor: default;
  }
}



