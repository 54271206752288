@mixin mq($breakpoint: pc) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin lang-ja {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
}

@mixin lang-en {
  font-family: flood-std, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

@function rem($pixels) {
  @return $pixels / $baseFontSize * 1rem;
}
// これでフォントサイズを計算しています。
// デザインが18pxの場合
// @include rem(18) と記載してください。