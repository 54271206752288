.l-main {
  // padding-left: 240px;
}

.c-main-container {
  position: relative;
  padding-bottom: 140px;
  @include mq(sp) {
    padding-bottom: 100px;
  }
  &::before,&::after {
    content: "";
    display: inline-block;
    position: fixed;
    @include mq(sp) {
      z-index: -1;
    }
  }
  &::before {
    right: 0;
    top: 80px;
    background: url(../images/background_illust_01.jpg) no-repeat top right/100% auto;
    height: 300/1920*100vw;
    width: clamp(133.3333px, 10.4166%, 10000px);
    @include mq(ie) {
      width: 10.4166%;
      height: 300px;
    }
    @include mq(sp) {
      top: 20px;
      width: 25vw;
      height: 37.5vw;
      background-size: contain;
    }
  }
  &::after {
    bottom: 12px;
    background: url(../images/background_illust_02.jpg) no-repeat top left/100% auto;
    height: 210/1920*100vw;
    margin-left: -2px;
    width: clamp(74.6662px, 5.8333%, 10000px);
    @include mq(ie) {
      width: 5.8333%;
    }
    @include mq(sp) {
      bottom: 12px;
      width: 17.5vw;
      height: 26.25vw;
      margin-left: 0;
      background-size: contain;
    }
  }
}