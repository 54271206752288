.l-header {
  padding: 76px 0 0;
  @include mq(sp) {
    padding: 30px 0;
  }
}

.c-header {
  position: relative;
  font-size: rem(18);
  @include z-index(header);
  @include mq(pc) {
    padding-right: 2px;
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    height: 100%;
    background: url(../images/header_gray_line.png) repeat-y right 0 #fff;
    overflow: hidden;
  }
  @include mq(sp) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  &.-is-fixed {
    position: fixed;
    animation: fadeIn .5s forwards;
  }
  &::before {
    @include mq(pc) {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      background: url(../images/header_yellow_line.png) repeat-y 0 0;
      width: 16px;
      height: 100%;
      z-index: 100;
    }
  }
  // &::after {
  //   @include mq(pc) {
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     content: "";
  //     background: url(../images/header_gray_line.png) repeat-y right 0;
  //     width: 4px;
  //     height: 100%;
  //     z-index: 100;
  //   }
  // }
  .c-button-box {
    margin: 24px 0 0 28px;
    padding-bottom: 20px;
    @include mq(sp) {
      margin: 24px 12px 0;
      padding-bottom: 0;
    }
    .c-button-basic {
      padding: 16px 0;
      font-size: rem(18);
      width: 198px;
      // line-height: 1.6;
      @include mq(sp) {
        width: 100%;
      }
      svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }
  }
}

.l-wrapper {
  @include mq(sp) {
    // position: relative;
    &.-is-open {
      .c-header {
        overflow-y: scroll;
        height: 100%;
        &::after {
          content: "";
          background: rgba($primaryColor, .9);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .c-global-nav {
        right: 0;
        opacity: 1;
        visibility: visible;
        overflow-y: scroll;
        z-index: 100;
        height: 100%;
      }
    }
  }
}



.c-header__logo {
  display: flex;
  justify-content: center;
  @include mq(sp) {
    padding: 0 12/400*100%;
    align-items: center;
    justify-content: space-between;
  }
  .c-global-nav-button {
    @include mq(sp) {
      position: fixed;
      right: 3%;
      top: 24px;
      z-index: 100;
    }
  }
}

.c-global-nav {
  @include mq(pc) {
    margin-top: 66px;
  }
  @include mq(sp) {
    position: fixed;
    top: 0;
    right: -100%;
    transition: .3s;
    width: 59.5vw;
    padding: 24px 0 40px;
    @include z-index(nav);
    background: #fff;
    &::before {
      content: "";
      position: absolute;
      left: -2px;
      top: 0;
      background: url(../images/header_gray_line.png) repeat-y 0 0;
      height: 100%;
      width: 4px;
      z-index: 2;
    }
  }
  .c-global-nav-button {
    @include mq(sp) {
      margin-left: auto;
      margin-right: 12px;
    }
  }
}

.c-global-nav__inner {
  @include mq(sp) {
    background: #fff;
    padding: 0;
  }
}

.c-header__logo-inner {
  @include mq(sp) {
    display: inherit;
  }
  svg {
    fill: $baseColor;
    width: 136px;
    height: 68px;
    @include mq(sp) {
      width: 72px;
      height: 36px;
    }
  }
}

.c-global-nav-button {
  border-radius: 50%;
  background: $baseColor;
  width: 48px;
  height: 48px;
  position: relative;
  @include mq(sp) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    fill: $primaryColor;
    width: 20px;
    height: 20px;
  }
}

.c-global-nav__list:not(:root) {
  @include mq(sp) {
    margin-top: 24px;
  }
}

.c-global-nav__link {
  padding: 14px 16px 14px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mq(sp) {
    padding-left: 16px;
  }
}

.c-global-nav__item {
  position: relative;
  transition: $transition;
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 4px;
    background: url(../images/header_nav_line.png) repeat 0 0;
  }
  svg {
    transition: .3s;
    width: 20px;
    height: 20px;
    fill: $baseColor;
  }
  &:last-child {
    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 4px;
      background: url(../images/header_nav_line.png) repeat 0 0;
    }
  }
  @include mq(pc) {
    &:hover {
      color: $primaryColor;
      background: $baseColor;
      svg {
        fill: $primaryColor;
        transform: translateX(5px);
      }
    }
  }
  &[aria-selected="true"] {
    position: relative;
    .c-global-nav__link {
      background: $baseColor;
      color: $primaryColor;
      svg {
        display: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      background: url(../images/header_active_mark.png) no-repeat 0 0;
      left: auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 68px;
      height: 28px;
    }
  }
}

.c-global-nav__label {

}

.c-global-nav__ja {
  line-height: 1.6;
  font-weight: bold;
}

.c-global-nav__en {
  display: block;
  font-size: rem(14);
  color: $primaryColor;
  @include lang-en;
}




